<mat-form-field class="dropdown" [class.thin-outline-field]="isContextMenu" [appearance]="isContextMenu ? 'outline' : 'standard'" flex="95%" (click)="$event.stopPropagation();$event.preventDefault();">
    <mat-label>{{ widgetMeta.config[prop].displayName }}</mat-label>
    <mat-select [value]="widgetMeta.config[prop].value"
        (selectionChange)="customPropertyChanged($event, prop)">
        <mat-option [value]="type" *ngFor="let type of optionsMap[prop]">
           <ng-container [ngSwitch]="type">
                <ng-container *ngSwitchCase="'Basic'">
                    <button mat-button color="primary">Basic</button>
                </ng-container> 
                <ng-container *ngSwitchCase="'Raised'">
                    <button mat-raised-button color="primary">Raised</button>
                </ng-container> 
                <ng-container *ngSwitchCase="'Stroked'">
                    <button mat-stroked-button color="primary">Stroked</button>
                </ng-container> 
                <ng-container *ngSwitchCase="'Flat'">
                    <button mat-flat-button color="primary">Flat</button>
                </ng-container>
                <ng-container *ngSwitchCase="'Fab'">
                    <button mat-fab extended>Fab</button>
                </ng-container> 
           </ng-container>
        </mat-option>
    </mat-select>
</mat-form-field>